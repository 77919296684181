import React from 'react';

import ContactCTA from '../components/contact-cta';
import Discover from '../components/discover';
import BrochureCTA from '../components/brochure-cta';

const getCallToAction = (cta, language) => {
  // If the CTA is undefined or just empty, return a default ContactCTA
  if (!cta || cta.length === 0) {
    return <ContactCTA language={language} />;
  }

  const item = cta[0];
  switch (item.name) {
    case 'contact-cta':
      return <ContactCTA language={language} title={item.title} description={item.description} />;
    case 'form-cta':
      return <ContactCTA language={language} title={item.title} />;
    case 'solutions-cta':
      return <Discover language={language} dark title={item.title} />;
    case 'brochure-cta':
      return <BrochureCTA language={language} dark title={item.title} brochures={item.downloads} />;
    default:
      return;
  }
};

export default getCallToAction;
