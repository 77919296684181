import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

import { ColumnSection, Column } from '../column-section';
import Image from '../image';
import { Languages } from '../../tools/languages';

import PlaceholderImage from '../../../static/images/placeholder.jpg';

import './index.scss';

const BrochureCTA = ({ language, dark, title, brochures }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [selectedBrochureUrl, setSelectedBrochureUrl] = useState(brochures[0].url);
  const [selectedBrochureName, setSelectedBrochureName] = useState(brochures[0].name);

  const downloadBrochure = async (event) => {
    if (name && email && selectedBrochureUrl && selectedBrochureName) {
      const slugs = window.location.pathname.split('/');
      const lastSlug = slugs.pop();
      const solutionType = lastSlug === '' ? slugs.pop() : lastSlug;

      // Inform the Bavak Sales team by triggering an Azure Function
      const response = await axios.post(process.env.BAVAK_BROCHURE_API_URL, {
        name: name,
        emailAddress: email,
        solution: solutionType,
        brochures: [selectedBrochureName],
      });

      if (response.status === 200) return setError();
    }

    event.preventDefault();
    return setError(<FormattedMessage id="brochure-required-fields-error-message" />);
  };

  const onSelectChange = (event) => {
    const brochureUrl = event.target.value;
    const brochureName = document.getElementById(brochureUrl).attributes['label'].value;

    setSelectedBrochureUrl(brochureUrl);
    setSelectedBrochureName(brochureName);
  };

  return (
    <ColumnSection dark={dark} className="download-brochure">
      <Column>
        <h3>{title ?? title}</h3>
        <form>
          <input
            placeholder={language === Languages.EN ? 'Name' : 'Naam'}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <input
            placeholder="E-mail"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <select name="brochures" id="brochures" onBlur={onSelectChange}>
            {brochures.map((brochure) => {
              return (
                <option
                  key={brochure.url}
                  label={brochure.name}
                  id={brochure.url}
                  value={brochure.url}
                >
                  {brochure.name}
                </option>
              );
            })}
          </select>
          {error && <p className="error">{error}</p>}
          <a
            className="download-link secondary-button"
            onClick={downloadBrochure}
            href={selectedBrochureUrl}
            title={selectedBrochureName}
            download={selectedBrochureName}
            target="_blank"
            rel="noreferrer"
          >
            Download
          </a>
        </form>
      </Column>
      <Column>
        <Image
          src={PlaceholderImage}
          alt={language === Languages.EN ? 'Bavak Office' : 'Kantoor van Bavak'}
        />
      </Column>
    </ColumnSection>
  );
};

BrochureCTA.propTypes = {
  language: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
};

BrochureCTA.defaultProps = {
  language: Languages.EN,
  dark: false,
};

export default BrochureCTA;
