import React from 'react';
import SEO from '../../../components/seo';
import SimpleHero from '../../../components/simple-hero';
import Navigation from '../../../components/navigation';
import BigParagraph from '../../../components/big-paragraph';

import { graphql } from 'gatsby';

import { Column, ColumnSection } from '../../../components/column-section';

import ReactMarkdown from 'react-markdown';
import { Languages } from '../../../tools/languages';
import { PrimaryButton } from '../../../components/buttons';
import { FormattedMessage } from 'react-intl';

import PlaceholderImage from '../../../../static/images/placeholder.jpg';

import getCallToAction from '../../../tools/get-call-to-action';
import RelatedNewsSection from '../../../components/related-news-section';

const EnglishAboutDetailPage = ({ location, data, language = Languages.EN }) => {
  const category = data.category;
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={category.title}
        description={category.description}
        lang={language}
      />
      <Navigation transparent />
      <main>
        <SimpleHero
          image={category.image ? category.image.childImageSharp.original.src : PlaceholderImage}
          alt={category.title}
        >
          <h1>{category.title}</h1>
          <p>{category.description}</p>
          <div>
            <PrimaryButton to="/contact/">
              <FormattedMessage id="hero-contact-cta" />
            </PrimaryButton>
          </div>
        </SimpleHero>
        <ColumnSection>
          {category.showRecentNews && <RelatedNewsSection language={language} />}
          <Column>
            {category.introduction && <BigParagraph>{category.introduction}</BigParagraph>}
            <ReactMarkdown>{category.content}</ReactMarkdown>
          </Column>
        </ColumnSection>
        {getCallToAction(category.CTA, language)}
      </main>
    </>
  );
};

export const query = graphql`
  query EnglishAboutDetail($slug: String!) {
    category: strapiAboutCategories(slug: { eq: $slug }) {
      slug
      title: title_en
      description: description_en
      introduction: introduction_en
      content: content_en
      showRecentNews: show_recent_news
      CTA {
        name
        title: title_en
        description: description_en
      }
      image {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`;

export default EnglishAboutDetailPage;
