import React from 'react';
import { Languages } from '../../tools/languages';
import { RelatedNews, RelatedNewsItem } from '../column-section';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import './index.scss';

const query = graphql`
  query RelatedNewsQuery {
    newsItems: allStrapiNewsItems(limit: 5, sort: { fields: [published], order: [DESC] }) {
      dutch: edges {
        node {
          id
          slug
          title: title_nl
          published(formatString: "D MMMM YYYY", locale: "NL")
        }
      }
      english: edges {
        node {
          id
          slug
          title: title_en
          published(formatString: "D MMMM YYYY", locale: "EN")
        }
      }
    }
  }
`;

const RelatedNewsSection = ({ language }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <RelatedNews title={language === Languages.NL ? 'Recent Nieuws' : 'Recent News'}>
          {language === Languages.NL
            ? data.newsItems.dutch.map((item) => {
                const { id, slug, title, published } = item.node;
                return (
                  <RelatedNewsItem
                    key={id}
                    to={`/nl/news/${slug}/`}
                    title={title}
                    time={published}
                  />
                );
              })
            : data.newsItems.english.map((item) => {
                const { id, slug, title, published } = item.node;
                return (
                  <RelatedNewsItem key={id} to={`/news/${slug}/`} title={title} time={published} />
                );
              })}
        </RelatedNews>
      )}
    />
  );
};

RelatedNewsSection.propTypes = {
  language: PropTypes.string,
};

RelatedNewsSection.defaultProps = {
  language: Languages.EN,
};

export default RelatedNewsSection;
