import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const BigParagraph = ({ children }) => {
  return <p className="introduction">{children}</p>;
};

BigParagraph.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BigParagraph;
