import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';

import './index.scss';

const SimpleHero = ({ useGatsbyImage, image, alt, children }) => {
  if (useGatsbyImage) {
    return (
      <section className="simple-hero">
        {image ? <Img fluid={image} alt={alt} /> : null}
        {children}
      </section>
    );
  }

  return (
    <section className="simple-hero">
      {image ? <img src={image} alt={alt} /> : null}
      {children}
    </section>
  );
};

SimpleHero.propTypes = {
  useGatsbyImage: PropTypes.bool,
  image: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
};

SimpleHero.defaultProps = {
  useGatsbyImage: false,
};

export default SimpleHero;
